const initialState = {
	lang: "en",
	currentPage: "About",
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "SET_LANGUAGE":
			return { ...state, lang: action.payload };
		case "CHANGE_CURRENT_PAGE":
			return { ...state, currentPage: action.payload };
		default:
			return state;
	}
};
