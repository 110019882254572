import React, { useState, useEffect } from "react";
import Header from "../Header";
import MenuSection from "./MenuSection";
import { firestore } from "../../base";
import LanguageMenu from "../LanguageMenu";
import "./menu.scss";

const Menu = () => {
	const [sections, setSections] = useState([]);
	const [language, setLanguage] = useState(
		localStorage.getItem("lang") || "en"
	);

	useEffect(() => {
		firestore
			.collection("menu")
			.doc("Categories")
			.get()
			.then((doc) => {
				setSections(Object.values(doc.data()));
			});
	}, []);

	const title = { en: "Menu", lv: "Ēdienkarte", ru: "Меню" };

	const sortedSections = () => {
		return sections.sort((a, b) => a.position - b.position);
	};

	return (
		<>
			<Header language={language} />
			<section>
				<div className="container">
					<h1 style={{ textAlign: "center", padding: "1.5em" }}>
						{title[language]}
					</h1>

					{sections.length ? (
						<div className="masonry-menu">
							{sortedSections().map((section, index) => (
								<MenuSection
									key={index}
									section={section}
									language={language}
								/>
							))}
						</div>
					) : (
						<p style={{ padding: "10em" }}>
							<span className={"loader"} />
						</p>
					)}
				</div>
			</section>
			<LanguageMenu setLanguage={setLanguage} language={language} />
		</>
	);
};

export default Menu;
