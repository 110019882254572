import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { firestore } from "../base";
import TextEditor from "./TextEditor";
import translate from "../translate";

const About = () => {
	const [content, setContent] = useState({});
	const [saving, setSaving] = useState(false);
	const lang = useSelector((state) => state.lang);

	useEffect(() => {
		firestore
			.collection("about")
			.doc("about")
			.onSnapshot((snapshot) => {
				setContent({ ...snapshot.data().content });
			});
	}, [lang]);

	const save = async (text) => {
		setSaving(true);

		let content = { [lang]: text };

		if (lang === "lv") {
			const res = await translate(text);
			content["en"] = res["en"];
		}

		await firestore
			.collection("about")
			.doc("about")
			.set({ content }, { merge: true });
		setSaving(false);
	};

	return (
		<>
			<button
				className="btn btn-outline-secondary btn-block btn-sm mb-3"
				disabled
			>
				About
			</button>
			<TextEditor
				text={content[lang]}
				callback={save}
				saving={saving}
				images={true}
			/>
		</>
	);
};

export default About;
