import React from "react";

const LoadingScreen = () => {
	return (
		<div className="loading">
			<div className="loading-spinner">
				<span className="spinner-border spinner-border-lg" role="status"></span>
			</div>
		</div>
	);
};

export default LoadingScreen;
