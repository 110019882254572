import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import firebase from "../base";

import NavBar from "./NavBar";
import About from "./About";
import News from "./News";

import "./css/style.scss";
import SignInForm from "./SignInForm";
import Menu from "./Menu";
import Gallery from "./Gallery";
import LoadingScreen from "./LoadingScreen";

const App = () => {
	const [authenticated, setAuthenticated] = useState(null);

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				setAuthenticated(true);
			} else {
				setAuthenticated(false);
			}
		});
	}, []);

	if (authenticated === null) return <LoadingScreen />;

	return (
		<div>
			<NavBar authenticated={authenticated} />
			{authenticated ? (
				<div className="container mt-3">
					<Switch>
						<Route exact path="/admin/" component={About} />
						<Route exact path="/admin/about" component={About} />
						<Route exact path="/admin/news" component={News} />
						<Route exact path="/admin/menu" component={Menu} />
						<Route exact path="/admin/gallery" component={Gallery} />
					</Switch>
				</div>
			) : (
				<SignInForm />
			)}
		</div>
	);
};

export default App;
