import React, { useState } from "react";
import { SignIn } from "./Auth";

const SignInForm = () => {
	const [email, setEmail] = useState("");
	const [pass, setPass] = useState("");
	const [error, setError] = useState("");

	const submitForm = async (e) => {
		e.preventDefault();
		try {
			await SignIn(email, pass);
		} catch (e) {
			setError(e.message);
		}
	};

	const changeEmail = (e) => {
		setError("");
		setEmail(e.target.value);
	};

	const changePass = (e) => {
		setError("");
		setPass(e.target.value);
	};

	return (
		<form onSubmit={submitForm} className="form-signin text-center mt-5">
			<h1 className="h3 mb-3 font-weight-normal">Sign In</h1>

			<div className="form-group">
				<label htmlFor="email" className="sr-only">
					Email Address
				</label>
				<input
					type="email"
					id="email"
					className={`form-control ${error && "is-invalid"}`}
					value={email}
					onChange={changeEmail}
					placeholder="Email address"
					required
					autoFocus
				/>

				<label htmlFor="pass" className="sr-only">
					Password
				</label>
				<input
					type="password"
					id="pass"
					className={`form-control ${error && "is-invalid"}`}
					value={pass}
					onChange={changePass}
					placeholder="Password"
					required
				/>
			</div>
			<button type="submit" className="btn btn-info btn-block">
				Sign In
			</button>
			<p className="text-danger">{error}</p>
		</form>
	);
};

export default SignInForm;
