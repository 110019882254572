import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { firestore } from "../base";

const newDish = {
	name: {
		en: "",
		lv: "",
		ru: "",
	},
	description: {
		en: "",
		lv: "",
		ru: "",
	},
	price: "",
	lrgPrice: "",
	active: true,
	category: "",
};

const Menu = () => {
	const [showEditor, setShowEditor] = useState(false);
	const [categories, setCategories] = useState([]);
	const [dishes, setDishes] = useState([]);
	const [currentCategory, setCurrentCategory] = useState("");
	const [saving, setSaving] = useState(false);
	const [dish, setDish] = useState({ ...newDish });

	const lang = useSelector((state) => state.lang);
	const menuRef = firestore.collection("menu");

	const saveDisable = () => {
		const { name, category, price } = dish;
		if (!price) return true;
		if (!category) return true;
		if (!name[lang]) return true;
		return false;
	};

	useEffect(() => {
		firestore.collection("menu").onSnapshot((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (doc.data().text) return;
				const cats = Object.keys(doc.data()).sort();
				setCategories(cats);
				setCatagory(cats[0]);
			});
		});
	}, []);

	const setCatagory = (category) => {
		setCurrentCategory(category);
		firestore
			.collection("menu")
			.doc(category)
			.collection("items")
			.orderBy("name", "asc")
			.onSnapshot((snapshot) => {
				const loadDishes = [];
				snapshot.forEach((dish) =>
					loadDishes.push({ ...dish.data(), id: dish.id })
				);
				setDishes(loadDishes);
			});
	};

	const saveAndClose = async () => {
		await save()
		resetForm()
	}

	const save = async () => {
		setSaving(true);
		const { name, description, category, price, lrgPrice } = dish;

		const newDish = { name, description, price, lrgPrice, active: true };

		if (dish.id) {
			await firestore
				.collection("menu")
				.doc(category)
				.collection("items")
				.doc(dish.id)
				.set({ ...newDish }, { merge: true })
			
			return null
		} else {
			newDish.name = name

			if (description) {
				newDish.description = description
			}

			await firestore
				.collection("menu")
				.doc(category)
				.collection("items")
				.add(newDish)
				.then(res => newDish.id = res.id)
			
			return newDish
		}
	};

	const resetForm = () => {
		setDish({ ...newDish });
		setSaving(false);
		setShowEditor(false);
	};

	const toggleActive = (dish) => {
		menuRef
			.doc(currentCategory)
			.collection("items")
			.doc(dish.id)
			.set({ active: !dish.active }, { merge: true });
	};

	const deleteDish = (dish) => {
		menuRef.doc(currentCategory).collection("items").doc(dish.id).delete();
	};

	const updateDish = (dish) => {
		setShowEditor(true);
		setDish({ ...newDish, ...dish, category: currentCategory });
	};

	const toggleEditor = () => {
		if (dish.id) {
			setDish({ ...newDish });
		} else {
			setShowEditor(!showEditor);
		}
	};

	return (
		<div>
			<button
				className="btn btn-outline-secondary btn-block btn-sm mb-3"
				onClick={toggleEditor}
			>
				New Dish
			</button>
			<form className={showEditor ? "d-show" : "d-none"}>
				<div className="form-row">

					<div className="form-group col-md-4">
						<label htmlFor="dish-category">Category</label>
						<select
							className="custom-select"
							name="dish-category"
							value={dish.category}
							onChange={(e) => setDish({ ...dish, category: e.target.value })}
							required
						>
							<option value="" disabled>
								Select a Category
							</option>
							{categories.map((category, index) => (
								<option key={index} value={category}>
									{category}
								</option>
							))}
						</select>
					</div>

					<div className="form-group col-md-4">
						<label htmlFor="dish-regular-price ">Price (regular)</label>
						<input
							type="number"
							className="form-control"
							name="dish-regular-price"
							value={dish.price}
							onChange={(e) => setDish({ ...dish, price: e.target.value })}
							required
						/>
					</div>

					<div className="form-group col-md-4">
						<label htmlFor="dish-large-price">Price (large)</label>
						<input
							type="number"
							className="form-control"
							name="dish-large-price"
							value={dish.lrgPrice}
							onChange={(e) => setDish({ ...dish, lrgPrice: e.target.value })}
						/>
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-md-6">
						<label htmlFor="dish-name">Name [EN]</label>
						<input
							type="text"
							className="form-control"
							name="dish-name"
							value={dish.name['en']}
							onChange={(e) =>
								setDish({
									...dish,
									name: { ...dish.name, en: e.target.value },
								})
							}
							required
						/>
					</div>

					<div className="form-group col-md-6">
						<label htmlFor="dish-description">Description [EN]</label>
						<input
							type="text"
							className="form-control"
							name="dish-description"
							value={dish.description['en']}
							onChange={(e) =>
								setDish({
									...dish,
									description: { ...dish.description, en: e.target.value },
								})
							}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-md-6">
						<label htmlFor="dish-name">Name [LV]</label>
						<input
							type="text"
							className="form-control"
							name="dish-name"
							value={dish.name['lv']}
							onChange={(e) =>
								setDish({
									...dish,
									name: { ...dish.name, lv: e.target.value },
								})
							}
							required
						/>
					</div>

					<div className="form-group col-md-6">
						<label htmlFor="dish-description">Description [LV]</label>
						<input
							type="text"
							className="form-control"
							name="dish-description"
							value={dish.description['lv']}
							onChange={(e) =>
								setDish({
									...dish,
									description: { ...dish.description, lv: e.target.value },
								})
							}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-md-6">
						<label htmlFor="dish-name">Name [RU]</label>
						<input
							type="text"
							className="form-control"
							name="dish-name"
							value={dish.name['ru']}
							onChange={(e) =>
								setDish({
									...dish,
									name: { ...dish.name, ru: e.target.value },
								})
							}
							required
						/>
					</div>

					<div className="form-group col-md-6">
						<label htmlFor="dish-description">Description [RU]</label>
						<input
							type="text"
							className="form-control"
							name="dish-description"
							value={dish.description['ru']}
							onChange={(e) =>
								setDish({
									...dish,
									description: { ...dish.description, ru: e.target.value },
								})
							}
						/>
					</div>
				</div>
				

				<div className="form-row mb-5">
					<div className="form-group col-md-12">
					{saving ? (
						<div className="float-right">
						<button
							className="btn btn-outline-success btn-sm mr-3"
							disabled={true}
						>
							<span
								className="spinner-border spinner-border-sm"
								role="status"
							></span>
						</button>
						<button
							className="btn btn-outline-success btn-sm"
							disabled={true}
						>
							<span
								className="spinner-border spinner-border-sm"
								role="status"
							></span>
							</button>
							</div>
					) : (
						<div className="float-right">
							<button
								onClick={saveAndClose}
								type="button"
								className="btn btn-outline-success btn-sm"
								disabled={saveDisable()}
							>
								Save and Close
							</button>
						</div>
					)}
					</div>
				</div>
			</form>

			<div className="dropdown d-md-none mb-2">
				<button
					className="btn btn-outline-secondary dropdown-toggle btn-block btn-sm"
					type="button"
					id="dropdownMenuLink"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					{currentCategory || "Categories"}
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
					{categories.map((category, index) => (
						<span
							href="#"
							className="dropdown-item"
							key={index}
							value={category}
							onClick={() => setCatagory(category)}
							style={{ cursor: "pointer" }}
						>
							{category}
						</span>
					))}
				</div>
			</div>

			<div className="row">
				<div className="col-md-2 d-none d-md-inline mt-3">
					<div
						className="nav flex-column nav-pills"
						id="v-pills-tab"
						role="tablist"
						aria-orientation="vertical"
					>
						{categories.map((category, index) => (
							<a
								className={`nav-link ${
									category === currentCategory && "active"
								}`}
								id="v-pills-home-tab"
								data-toggle="pill"
								href="#v-pills-home"
								role="tab"
								aria-controls="v-pills-home"
								aria-selected={category === currentCategory}
								key={index}
								onClick={() => setCatagory(category)}
							>
								{category}
							</a>
						))}
					</div>
				</div>

				<div className="col-md-10 mt-3">
					{dishes.map((dish) => (
						<div
							key={dish.id}
							className={`card mb-2 ${
								dish.active ? "border-success" : "border-danger"
							}`}
						>
							<div className="card-body">
								<div className="row">
									<div className="col-xl-8">
										<h5>{dish.name[lang]}</h5>
										<h6 className="card-subtitle mt-2 mb-2 text-muted">
											{dish.description[lang] && `${dish.description[lang]} - `}
											{dish.price} {dish.lrgPrice && `/ ${dish.lrgPrice}`}
										</h6>
									</div>
									<div className="col-xl-4">
										<div className="btn-trio mt-2">
											<button
												onClick={() => toggleActive(dish)}
												className={`btn btn-sm ${
													dish.active ? "btn-success" : "btn-danger"
												}`}
											>
												{dish.active ? "Turn Off" : "Turn On"}
											</button>
											<button
												onClick={() => updateDish(dish)}
												className="btn btn-outline-info btn-sm"
											>
												Update
											</button>
											<button
												className="btn btn-outline-danger btn-sm"
												data-toggle="collapse"
												data-target={`#confirm-delete-${dish.id}`}
												aria-expanded="false"
												aria-controls={`confirm-delete-${dish.id}`}
											>
												Delete
											</button>
										</div>
										<div
											className="collapse mt-2 text-center"
											id={`confirm-delete-${dish.id}`}
										>
											<button
												className="btn btn-danger block btn-sm btn-block"
												onClick={() => deleteDish(dish)}
											>
												Are you sure?
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Menu;
