import React from "react";

const Footer = () => {
  return (
    <footer className="footer-area section-gap">
      <div className="container">
        <div className="footer-bottom row align-items-center justify-content-between">
          <p className="footer-text m-0 col-lg-6 col-md-12">
            Copyright © {new Date().getFullYear()}
            <br />
            Site created by{" "}
            <a
              href="https://stuartsewell.dev"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stuart Sewell
            </a>
            <br />
            Template made with <span className="lnr lnr-heart" /> by{" "}
            <a
              href="https://colorlib.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Colorlib
            </a>
            <br />
            <sup className="text-dark">
              v1.0.2
            </sup>
          </p>
          <div className="col-lg-6 col-sm-12 footer-social">
            <a
              href="https://www.facebook.com/kafe53"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook" />
            </a>
            <a
              href="https://www.instagram.com/explore/locations/2979950/cafe-53/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
