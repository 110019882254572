import React from "react";

const LanguageMenu = ({ language, setLanguage }) => {
  const handleSetLanguage = lang => {
    setLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  return (
    <div
      className="fixed-lang-menu"
      style={{ boxShadow: "-1px 0px 5px 0px rgba(0,0,0,0.3)" }}
    >
      <p>
        <span
          className={language === "en" ? "active-lang" : null}
          onClick={() => handleSetLanguage("en")}
        >
          EN
        </span>
        <br />
        <span
          className={language === "lv" ? "active-lang" : null}
          onClick={() => handleSetLanguage("lv")}
        >
          LV
        </span>
        <br />
        <span
          className={language === "ru" ? "active-lang" : null}
          onClick={() => handleSetLanguage("ru")}
        >
          RU
        </span>
      </p>
    </div>
  );
};

export default LanguageMenu;
