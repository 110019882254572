import React from "react";
import Breaker from "./Breaker";

const home = { en: "Home", lv: "Mājas", ru: "Главная" };

const about = { en: "About", lv: "Par mums", ru: "О нас" };

const news = { en: "News", lv: "Aktualitātes", ru: "Актуально" };

const menu = { en: "Menu", lv: "Ēdienkarte", ru: "Меню" };

const gallery = { en: "Gallery", lv: "Galerija", ru: "Галерея" };

const contact = { en: "Contact", lv: "Kontakti", ru: "Контакты" };

const Header = ({ language }) => {
	return (
		<>
			<header className="header-area header-fixed">
				<div className="">
					<div className="header-wrap">
						<div className="header-top d-flex justify-content-between align-items-center navbar-expand-md">
							<div className="col-md-4 col-lg-5 menu-left">
								<a href="/">{home[language]}</a>
								<a href="/#/about" style={{whiteSpace: 'nowrap'}}>{about[language]}</a>
								<a href="/#/news">{news[language]}</a>
							</div>
							<div className="col-4 col-lg-2 logo">
								<a href="/">
									<img className="mx-auto" src="img/logo.jpg" alt="" />
								</a>
							</div>
							<nav className="col-md-4 col-lg-5 navbar navbar-expand-md justify-content-end">
								<button
									className="navbar-toggler"
									type="button"
									data-toggle="collapse"
									data-target="#collapsibleNavbar"
								>
									<span className="lnr lnr-menu"></span>
								</button>

								<div
									className="collapse navbar-collapse menu-right"
									id="collapsibleNavbar"
								>
									<ul className="navbar-nav justify-content-center w-100">
										<li className="nav-item hide-lg">
											<a className="nav-link" href="/">
												{home[language]}
											</a>
										</li>
										<li className="nav-item hide-lg">
											<a className="nav-link" href="/#/about">
												{about[language]}
											</a>
										</li>
										<li className="nav-item hide-lg">
											<a className="nav-link" href="/#/news">
												{news[language]}
											</a>
										</li>

										<li className="nav-item">
											<a className="nav-link" href="/#menu_area">
												{menu[language]}
											</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="/#gallery_area">
												{gallery[language]}
											</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="/#contact_area">
												{contact[language]}
											</a>
										</li>
									</ul>
								</div>
							</nav>
						</div>
					</div>
				</div>
			</header>
			<Breaker />
		</>
	);
};

export default Header;
