import firebase from "../base";

export const SignOut = () => {
	firebase
		.auth()
		.signOut()
		.then(() => {
			console.info("Successfully signed out.");
		})
		.catch((e) => console.error("Sign out was unsuccessful: ", e));
};

export const SignIn = (email, pass) => {
	return firebase.auth().signInWithEmailAndPassword(email, pass);
};
