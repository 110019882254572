import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_SENDER_ID,
};

const app = initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

firestore.settings({
	cacheSizeBytes: firestore.CACHE_SIZE_UNLIMITED,
});
// firestore.enablePersistence();

export const storage = firebase.storage();

export const imageUpload = (file, location) => {
	return storage
		.ref()
		.child(location)
		.child(file.name + ((Date.now() / 1000) | 0))
		.put(file)
		.then((res) => res.ref.getDownloadURL());
};

export default app;
