import React from "react";
import { menuItems } from "./data";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SignOut } from "./Auth.js";

const NavBar = ({ authenticated }) => {
	const lang = useSelector((state) => state.lang);

	const dispatch = useDispatch();

	const setLanguage = (e) => {
		dispatch({
			type: "SET_LANGUAGE",
			payload: e.target.value,
		});
	};

	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
			<div className="container">
				<span className="navbar-brand">Cafe 53 Admin</span>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav mr-auto">
						{authenticated &&
							menuItems.map((item, index) => {
								return (
									<li className="nav-item" key={index}>
										<Link
											className="nav-link"
											to={item.url}
											onClick={() =>
												dispatch({
													type: "CHANGE_CURRENT_PAGE",
													payload: item.label[lang],
												})
											}
										>
											{item.label[lang]}
										</Link>
									</li>
								);
							})}
					</ul>
					{authenticated && (
						<ul className="navbar-nav my-2 my-lg-0">
							<li className="nav-item mr-2">
								<select
									className="custom-select language-select mb-2 mb-md-0"
									onChange={setLanguage}
									defaultValue="title"
								>
									<option value="en">Language: EN</option>
									<option value="lv">Language: LV</option>
									<option value="ru">Language: RU</option>
								</select>
							</li>
							<li className="nav-item">
								<button className="btn btn-outline-secondary" onClick={SignOut}>
									Sign Out
								</button>
							</li>
						</ul>
					)}
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
