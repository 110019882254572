import React, { useState } from "react";
import Header from "./Header";
import Banner from "./Banner";
import MenuArea from "./MenuArea";
import GalleryArea from "./GalleryArea";
import ContactArea from "./ContactArea";
import Footer from "./Footer";
import LanguageMenu from "./LanguageMenu";

function App() {
	const [language, setLanguage] = useState(
		localStorage.getItem("lang") || "en"
	);

	return (
		<>
			<Header language={language} />
			<Banner language={language} />
			<MenuArea language={language} />
			<GalleryArea language={language} />
			<ContactArea language={language} />
			<Footer language={language} />
			<LanguageMenu language={language} setLanguage={setLanguage} />
		</>
	);
}

export default App;
