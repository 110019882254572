import React from "react";
import Parser from "html-react-parser";

const Post = ({ content, date, language }) => {
	return (
		<div className="div p-4">
			<h6 className="mb-2">{date.toDate().toDateString()}</h6>
			{Parser(content[language])}
		</div>
	);
};

export default Post;
