import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { imageUpload } from "../base";

const TextEditor = ({
	text,
	callback,
	saving,
	images = false,
	height = 500,
}) => {
	const [content, setContent] = useState("");
	const [saveDisable, setSaveDisable] = useState(true);

	useEffect(() => {
		setContent(text);
	}, [text]);

	const handleEditorChange = (val) => {
		setSaveDisable(false);
		setContent(val);
	};

	const handleClick = () => {
		setSaveDisable(true);
		callback(content);
	};

	const toolbar = images
		? "h1 h2 h3 | bold italic | removeformat | image"
		: "h1 h2 h3 | bold italic | removeformat";

	if (!text) return <div />;

	return (
		<div className="text-editor">
			<Editor
				value={text}
				apiKey={process.env.REACT_APP_TINYMCE}
				init={{
					height,
					menubar: false,
					plugins: "image",
					toolbar,
					icons: "material",
					statusbar: false,
					images_upload_handler: function (
						blobInfo,
						success,
						failure,
						progress
					) {
						imageUpload(blobInfo.blob(), "gallery")
							.then(success)
							.catch(failure);
					},
				}}
				onEditorChange={handleEditorChange}
			/>
			{saving ? (
				<button
					className="btn btn-outline-success btn-sm float-right btn-editor"
					disabled={true}
				>
					<span
						className="spinner-border spinner-border-sm"
						role="status"
					></span>
				</button>
			) : (
				<button
					onClick={handleClick}
					className="btn btn-outline-success btn-sm float-right btn-editor"
					disabled={saveDisable}
				>
					Save
				</button>
			)}
		</div>
	);
};

export default TextEditor;
