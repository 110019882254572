import React from "react";

const Banner = () => {
  return (
    <section className="home-banner-area relative">
      <div className="container">
        <div className="row height align-items-center justify-content-center">
          <div className="home-banner-content col-lg-5">
            <h1>Cafe 53</h1>
            <hr />
            <p>Jomas Iela 53, Jūrmala</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
