import React from "react";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/cafe53-943ff.appspot.com/o/IMG_0515.jpg?alt=media&token=ace975b5-0488-4890-8bf7-e8d080e0fdbb",
  "https://firebasestorage.googleapis.com/v0/b/cafe53-943ff.appspot.com/o/IMG_3587.jpg?alt=media&token=5c1831e7-dc12-4bf0-ab7a-3884182b9fa3",
  "https://firebasestorage.googleapis.com/v0/b/cafe53-943ff.appspot.com/o/IMG_0782.jpg?alt=media&token=09183219-9f73-4d75-8c1b-06562208f60c",
  "https://firebasestorage.googleapis.com/v0/b/cafe53-943ff.appspot.com/o/IMG_2620.jpg?alt=media&token=8e36046f-20f8-4627-8749-7c0fc731b1d8",
  "https://firebasestorage.googleapis.com/v0/b/cafe53-943ff.appspot.com/o/IMG_1034.jpg?alt=media&token=1bd49a6d-3d3f-43a5-a352-80ab73bedc0e",
  "https://firebasestorage.googleapis.com/v0/b/cafe53-943ff.appspot.com/o/IMG_4952.jpg?alt=media&token=7ba06c30-19cd-4fe6-8b44-0b08317421db",
  "https://firebasestorage.googleapis.com/v0/b/cafe53-943ff.appspot.com/o/IMG_5959.jpg?alt=media&token=021e57c2-9ed5-4e0c-8577-184ddd6a29a7",
  "https://firebasestorage.googleapis.com/v0/b/cafe53-943ff.appspot.com/o/IMG_6045.jpg?alt=media&token=7068a5f7-5d22-4981-b00a-d6895b7bd5f3"
]

const GalleryArea = () => {
  return (
    <section className="gallery-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="offset-lg-1 col-lg-5 col-md-5 col-sm-6">
            <div className="single-gallery mt--280">
              <div className="overlay"></div>
              <figure>
                <img
                  src={images[0]}
                  className="img-fluid w-100"
                  alt=""
                />
              </figure>
              <div className="icon">
                <a
                  href={images[0]}
                  className="photo-gallery-pop-up"
                >
                  <span className="lnr lnr-cross"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-6">
            <div className="single-gallery">
              <div className="overlay"></div>
              <figure>
                <img
                  src={images[1]}
                  className="img-fluid w-100"
                  alt=""
                />
              </figure>
              <div className="icon">
                <a
                  href={images[1]}
                >
                  <span className="lnr lnr-cross"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="single-gallery mt--280">
              <div className="overlay"></div>
              <figure>
                <img
                  src={images[2]}
                  className="img-fluid w-100"
                  alt=""
                />
              </figure>
              <div className="icon">
                <a
                  href={images[2]}
                  className="photo-gallery-pop-up"
                >
                  <span className="lnr lnr-cross"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="single-gallery">
              <div className="overlay"></div>
              <figure>
                <img
                  src={images[3]}
                  className="img-fluid w-100"
                  alt=""
                />
              </figure>
              <div className="icon">
                <a
                  href={images[3]}
                  className="photo-gallery-pop-up"
                >
                  <span className="lnr lnr-cross"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="single-gallery mt--280">
              <div className="overlay"></div>
              <figure>
                <img
                  src={images[4]}
                  className="img-fluid w-100"
                  alt=""
                />
              </figure>
              <div className="icon">
                <a
                  href={images[4]}
                  className="photo-gallery-pop-up"
                >
                  <span className="lnr lnr-cross"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="single-gallery">
              <div className="overlay"></div>
              <figure>
                <img
                  src={images[5]}
                  className="img-fluid w-100"
                  alt=""
                />
              </figure>
              <div className="icon">
                <a
                  href={images[5]}
                  className="photo-gallery-pop-up"
                >
                  <span className="lnr lnr-cross"></span>
                </a>
              </div>
            </div>
          </div>
          
          <div className="offset-lg-1 col-lg-5 col-md-5 col-sm-6">
            <div className="single-gallery mt--120">
              <div className="overlay"></div>
              <figure>
                <img
                  src={images[6]}
                  className="img-fluid w-100"
                  alt=""
                />
              </figure>
              <div className="icon">
                <a
                  href={images[6]}
                  className="photo-gallery-pop-up"
                >
                  <span className="lnr lnr-cross"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="contact_area" />
    </section>
  );
};

export default GalleryArea;
