import React from "react";

const ContactArea = () => {
  return (
    <section className="contact-area section-gap">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div id="contactMap" />
          </div>
          <div className="offset-lg-1 col-lg-5 col-md-6">
            <div className="section-title relative">
              <h1>Cafe 53</h1>
              <div className="mb-40">
                <div className="flex-center my-2">
                  <span class="material-symbols-outlined">
                    location_on
                  </span>
                  <span>
                    Jomas iela 53, Jūrmala, LV-2015
                  </span>
                </div>
                <div className="flex-center my-2">
                  <span class="material-symbols-outlined">
                    phone_in_talk
                  </span>
                  +371 67 81 17 71
                </div>
                <div className="flex-center my-2">
                  <span class="material-symbols-outlined">
                    phone_iphone
                  </span>
                  +371 27 45 76 62
                </div>
                <div className="flex-center my-2 ">
                  <span class="material-symbols-outlined">
                    mail
                  </span>
                  <a href="mailto:info@cafe53.lv" className="no-color">info@cafe53.lv</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactArea;
