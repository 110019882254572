import React, { useState, useEffect } from "react";
import { firestore } from "../../base";
import Parser from "html-react-parser";
import Header from "../Header";
import LanguageMenu from "../LanguageMenu";

const About = () => {
  const [text, setText] = useState("");
  const [language, setLanguage] = useState(
    localStorage.getItem("lang") || "en"
  );

  useEffect(() => {
    firestore
      .collection("about")
      .doc("about")
      .get()
      .then(doc => {
        setText(doc.data().content[language]);
      });
  }, [language]);

  const title = { en: "About", lv: "Par", ru: "Про" };

  return (
    <>
      <Header language={language} />
      <section>
        <div className="container">
          <h1 style={{ textAlign: "center", padding: "1.5em" }}>
            {title[language]}
          </h1>
          {!text ? (
            <p style={{ padding: "10em" }}>
              <span className={"loader"} />
            </p>
          ) : (
            <div>{Parser(text)}</div>
          )}
        </div>
      </section>
      <LanguageMenu language={language} setLanguage={setLanguage} />
    </>
  );
};

export default About;
