import React, { useState, useEffect } from "react";
import { firestore } from "../base";

const titleText = {
	en: "Sample Menu",
	lv: "Ēdienkarte",
	ru: "Меню",
};

const buttonText = {
	en: "Full Menu",
	lv: "Pilna Ēdienkarte",
	ru: "Полное меню",
};

const MenuArea = ({ language }) => {
	const [snacks, setSnacks] = useState([]);
	const [mains, setMains] = useState([]);
	const [desserts, setDesserts] = useState([]);
	const [blurb, setBlurb] = useState("");

	useEffect(() => {
		const menuRef = firestore.collection("menu");

		const getBlurb = async (lang) => {
			const data = await firestore.collection("menu").doc("Blurb").get();
			const content = data.data();
			setBlurb(content.text[lang]);
		};

		const get = async (type) => {
			const dishes = [];
			await menuRef
				.doc(type)
				.collection("items")
				.limit(2)
				.get()
				.then((doc) => {
					doc.forEach((d) => dishes.push(d.data()));
				});
			if (type === "starters") setSnacks(dishes);
			if (type === "mains") setMains(dishes);
			if (type === "sweets") setDesserts(dishes);
		};

		get("starters");
		get("mains");
		get("sweets");
		getBlurb(language);
	}, [language]);

	const displayItems = (items) => {
		if (!items.length) return;
		return (
			<ul className="list">
				<li>
					<p className="menu-item">{`${items[0].name[language]} ........... €${items[0].price}`}</p>
				</li>
				{/* <li>
					<p className="menu-item">{`${items[1].name[language]} ........... €${items[1].price}`}</p>
				</li> */}
			</ul>
		);
	};

	return (
		<section className="menu-area">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-5 col-md-5">
						<div className="section-title relative">
							<h1>{titleText[language]}</h1>
							<p>{blurb}</p>
							<a href="/#/menu" className="primary-btn text-uppercase">
								{buttonText[language]}
							</a>
						</div>
					</div>
					<div className="col-lg-7 col-md-7">
						<div className="menu-list">
							<div className="single-menu">
								<h3>Starters</h3>
								{displayItems(snacks)}
							</div>

							<div className="single-menu">
								<h3>Main Courses</h3>
								{displayItems(mains)}
							</div>

							<div className="single-menu">
								<h3>Desserts</h3>
								{displayItems(desserts)}
								<span id="gallery_area" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default MenuArea;
