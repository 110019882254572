const translate = async (text) => {
	return await fetch(
		"https://us-central1-cafe53-943ff.cloudfunctions.net/translate",
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ text }),
		}
	).then((resp) => resp.json());
};

export default translate;
