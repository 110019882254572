import React from "react";
import App from "./App";

import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "./reducer";

const store = createStore(
	reducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const NewAdmin = () => {
	return (
		<Provider store={store}>
			<App />
		</Provider>
	);
};

export default NewAdmin;
