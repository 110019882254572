export const menuItems = [
	{
		label: {
			en: "About",
			lv: "About",
			ru: "About",
		},
		url: "/admin/about",
	},
	{
		label: {
			en: "News",
			lv: "News",
			ru: "News",
		},
		url: "/admin/news",
	},
	{
		label: {
			en: "Menu",
			lv: "Menu",
			ru: "Menu",
		},
		url: "/admin/menu",
	},
	// {
	// 	label: {
	// 		en: "Gallery",
	// 		lv: "Gallery",
	// 		ru: "Gallery",
	// 	},
	// 	url: "/admin/gallery",
	// },
];
