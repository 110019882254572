import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import Admin from "./Admin";
import Public from "./Public";
import About from "./Public/about";
import News from "./Public/blog";
import Menu from "./Public/menu";

class App extends React.Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route path="/admin" component={Admin} />
					<Route path="/about" component={About} />
					<Route path="/news" component={News} />
					<Route path="/menu" component={Menu} />
					<Route path="/" component={Public} />
				</Switch>
			</Router>
		);
	}
}

export default App;
