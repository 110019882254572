import React, { useState, useEffect } from "react";
import { firestore } from "../../base";

const MenuSection = ({ section, language }) => {
	const [dishes, setDishes] = useState([]);

	console.log(section)

	useEffect(() => {
		const sectionRef = firestore
			.collection("menu")
			.doc(section.key)
			.collection("items");
		sectionRef.get().then((docs) => {
			const dishesArr = [];
			docs.forEach((doc) => dishesArr.push(doc.data()))
			setDishes(dishesArr);
		});
	}, [section]);

	if (!dishes.length) return null;
	return (
		<div>
			<div className="single-menu">
				<h3>{section[language]}</h3>
				<ul className="list">
					{dishes.map((dish, index) => (
						<li key={index}>
							<p className="menu-item">
								{dish.name[language]} ...........{" "}
								<span>
									€{dish.price} {dish.lrgPrice && `/ €${dish.lrgPrice}`}
								</span>
							</p>
							{dish.description[language] && (
								<p class="menu-description">({dish.description[language]})</p>
							)}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default MenuSection;
