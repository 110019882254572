import React, { useState, useEffect } from "react";
import { firestore } from "../../base";
import Header from "../Header";
import Post from "./Post";
import LanguageMenu from "../LanguageMenu";

const Blog = () => {
	const [posts, setPosts] = useState([]);
	const [language, setLanguage] = useState(
		localStorage.getItem("lang") || "en"
	);

	useEffect(() => {
		firestore
			.collection("news")
			.orderBy("date", "desc")
			.get()
			.then((docs) => {
				const postsArr = [];
				docs.forEach((doc) => postsArr.push(doc.data()));
				setPosts(postsArr);
			});
	}, []);

	const title = { en: "News", lv: "Aktualitātes", ru: "Актуально" };

	return (
		<>
			<Header language={language} />
			<section>
				<div className="container">
					<h1 style={{ textAlign: "center", padding: "1.5em" }}>
						{title[language]}
					</h1>
					<div className="row row-cols-1 row-cols-md-2">
						{posts.length ? (
							posts.map((post) => (
								<Post key={post.id} {...post} language={language} />
							))
						) : (
							<p style={{ padding: "10em" }}>
								<span className={"loader"} />
							</p>
						)}
					</div>
				</div>
			</section>
			<LanguageMenu setLanguage={setLanguage} language={language} />
		</>
	);
};

export default Blog;
