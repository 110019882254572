import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { firestore } from "../base";
import TextEditor from "./TextEditor";
import Parser from "html-react-parser";
import translate from "../translate";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const clearEditor = {
	en: "<div></div>",
	lv: "<div></div>",
	ru: "<div></div>",
};

const News = () => {
	const [content, setContent] = useState(clearEditor);
	const [saving, setSaving] = useState(false);
	const [posts, setPosts] = useState([]);
	const [id, setId] = useState(null);
	const [showEditor, setShowEditor] = useState(false);

	const lang = useSelector((state) => state.lang);

	const editorRef = useRef(null);
	const executeScroll = () => scrollToRef(editorRef);

	useEffect(() => {
		firestore
			.collection("news")
			.orderBy("date", "desc")
			.onSnapshot((snapshot) => {
				const posts = [];
				snapshot.forEach((doc) => posts.push({ id: doc.id, ...doc.data() }));
				setPosts(posts);
			});
	}, []);

	const save = async (text) => {
		setSaving(true);

		const newsRef = firestore.collection("news");

		if (id) {
			return newsRef
				.doc(id)
				.set({ content: { [lang]: text } }, { merge: true })
				.then(resetEditor);
		} else {
			const translated = await translate(text);

			let newPost = {
				title: "",
				content: translated,
				date: new Date(),
			};

			return newsRef.add(newPost).then(resetEditor);
		}
	};

	const editPost = (post) => {
		setId(post.id);
		setShowEditor(true);
		executeScroll();
		setContent(post.content);
	};

	const deletePost = (post) => {
		firestore.collection("news").doc(post).delete();
		resetEditor();
	};

	const resetEditor = () => {
		setContent({ ...clearEditor });
		setSaving(false);
		setId(null);
		setShowEditor(false);
	};

	const clearContent = () => {
		if (id) {
			setId(null);
			setContent(clearEditor);
		}
	};

	return (
		<div>
			<p>
				<button
					className={`btn btn-outline-secondary btn-block btn-sm ${
						id && "d-none"
					}`}
					onClick={() => {
						setShowEditor(!showEditor);
					}}
				>
					New Post
				</button>
				<button
					className={`btn btn-outline-secondary btn-block btn-sm ${
						!id && "d-none"
					}`}
					onClick={clearContent}
				>
					New Post
				</button>
			</p>
			<div
				className={showEditor ? "d-block" : "d-none"}
				id="new-post"
				ref={editorRef}
			>
				<TextEditor
					text={content[lang]}
					callback={save}
					saving={saving}
					images={true}
					height="330"
				/>
			</div>
			<div className="card-columns">
				{posts.map((post) => {
					return (
						<div className="card" key={post.id}>
							<div className="card-body">
								<h6 className="card-subtitle mb-2">
									{post.date.toDate().toDateString()}
								</h6>
								{Parser(post.content[lang])}
							</div>
							<div className="card-body text-center">
								<div className="btn-pair">
									<button
										onClick={() => editPost(post)}
										className="btn btn-outline-info block btn-sm"
									>
										Update
									</button>
									<button
										className="btn btn-outline-danger block btn-sm"
										data-toggle="collapse"
										data-target={`#confirm-delete-${post.id}`}
										aria-expanded="false"
										aria-controls={`confirm-delete-${post.id}`}
									>
										Delete
									</button>
								</div>
								<div className="collapse mt-2" id={`confirm-delete-${post.id}`}>
									<button
										className="btn btn-danger block btn-sm"
										onClick={() => deletePost(post.id)}
									>
										Are you sure?
									</button>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default News;
